import { useSelector } from 'react-redux'

import { ChainID } from 'constant'
import { AppState } from 'store'

type UseChainProps = {
  chainId: ChainID
}

export const useChain = ({ chainId = ChainID.A8 }: UseChainProps) => {
  return useSelector((state: AppState) => state.chain[chainId])
}

export const useChains = () => {
  return useSelector((state: AppState) => state.chain)
}
