import { ChainID } from 'constant/index'

import type { IPrice } from 'types/product.type'

export const DEFAULT_GACHA_PRICE: IPrice = {
  priceCCP: 0,
  originalPriceCCP: 0,
  priceBadges: [],
  priceToken: {
    price: 0,
    tokenName: '',
    tokenAddress: '',
    chainId: ChainID.A8,
  },
  originalPriceToken: {
    price: 0,
    tokenName: '',
    tokenAddress: '',
    chainId: ChainID.A8,
  },
}

export const GACHA_SUPPORTED_CHAINS = [ChainID.A8, ChainID.Etherlink]
