import { Fragment, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Form, type FormInstance } from 'antd'

import SelectTokenFormItem from 'components/systems/form-item/SelectTokenFormItem'
import InputLabel from 'components/systems/input/InputLabel'
import WeightFormItem from './shared/WeightFormItem'
import RewardNumberFormItem from './shared/RewardNumberFormItem'
import TypeFormItem from './shared/TypeFormItem'
import TierFormItem from './shared/TierFormItem'
import AvailableFormItem from './shared/AvailableFormItem'

import { useChain } from 'hooks/chain/useChain'

import { type RewardItemProps } from '.'

function RewardTokenItem({
  form,
  prefixNamePath,
}: RewardItemProps<{ form: FormInstance }>) {
  const rewardId = Form.useWatch(['rewards', prefixNamePath, '_id'], form)
  const chainId = Form.useWatch('chainId', form)
  const isEdit = !!rewardId

  const { paymentCurrencies } = useChain({ chainId })

  const tokenAmount = Form.useWatch(
    ['rewards', prefixNamePath, 'config', 'spent'],
    form,
  )
  const numberOfRewards = Form.useWatch(
    ['rewards', prefixNamePath, 'total'],
    form,
  )

  const gachaId = useParams()?.gachaId ?? ''

  useEffect(() => {
    form.setFieldValue(
      ['rewards', prefixNamePath, 'config', 'chainId'],
      chainId,
    )
  }, [chainId, form, prefixNamePath])

  return (
    <Fragment>
      <TierFormItem prefixNamePath={prefixNamePath} />

      <SelectTokenFormItem
        name={[prefixNamePath, 'config', 'symbol']}
        form={form}
        isEdit={!!gachaId}
      />

      <Form.Item
        name={[prefixNamePath, 'config', 'spent']}
        rules={[
          {
            required: true,
            message: 'Token amount must be number',
          },
        ]}
      >
        <InputLabel.Number
          label="Token Amount"
          placeholder="Enter the number of tokens"
          type="number"
          min={0}
          disabled={isEdit}
          onChange={(value) => {
            if (!value || !numberOfRewards) {
              return form.setFieldValue(
                ['rewards', prefixNamePath, 'config', 'amount'],
                0,
              )
            }
            return form.setFieldValue(
              ['rewards', prefixNamePath, 'config', 'amount'],
              Number(value) / numberOfRewards,
            )
          }}
        />
      </Form.Item>

      <Form.Item
        shouldUpdate={(prevValues, nextValues) =>
          prevValues.rewards?.[prefixNamePath]?.config?.symbol !==
          nextValues.rewards?.[prefixNamePath]?.config?.symbol
        }
        noStyle
        hidden
      >
        {({ getFieldValue, setFieldValue }) => {
          const symbol = getFieldValue([
            'rewards',
            prefixNamePath,
            'config',
            'symbol',
          ])

          const currency = paymentCurrencies?.find(
            (currency) => currency.symbol === symbol,
          )

          setFieldValue(
            ['rewards', prefixNamePath, 'config', 'tokenAddress'],
            currency?.address ?? '',
          )

          return <Form.Item name={[prefixNamePath, 'config', 'tokenAddress']} />
        }}
      </Form.Item>

      <Form.Item name={[prefixNamePath, 'config', 'chainId']} noStyle hidden />

      <RewardNumberFormItem
        disabled={isEdit}
        prefixNamePath={prefixNamePath}
        onChange={(value) => {
          form.setFieldValue(['rewards', prefixNamePath, 'available'], value)
          if (!value || !tokenAmount) {
            return form.setFieldValue(
              ['rewards', prefixNamePath, 'config', 'amount'],
              0,
            )
          }
          return form.setFieldValue(
            ['rewards', prefixNamePath, 'config', 'amount'],
            tokenAmount / Number(value),
          )
        }}
      />
      {isEdit && <AvailableFormItem prefixNamePath={prefixNamePath} />}

      <Form.Item name={[prefixNamePath, 'config', 'amount']}>
        <InputLabel.Number
          label="Rewards per User"
          placeholder="Please enter the Token Amount and Total Rewards to calculate the Rewards per User"
          disabled
        />
      </Form.Item>

      <WeightFormItem prefixNamePath={prefixNamePath} />

      {/* NOTE: for css dom correctly, type must be placed at the very bottom */}
      <TypeFormItem prefixNamePath={prefixNamePath} />
    </Fragment>
  )
}

export default RewardTokenItem
