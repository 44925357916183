import { useEffect, useMemo } from 'react'

import {
  Form,
  type FormInstance,
  type FormItemProps,
  Image,
  Select,
  Space,
  Typography,
} from 'antd'

import { useChain } from 'hooks/chain/useChain'

import { getTokenIcon } from 'helper/nft'

import { ChainID } from 'constant'

import './index.less'

type SelectTokenFormItemProps = Omit<FormItemProps, 'name'> & {
  name: (number | string)[]
  form: FormInstance
  isEdit?: boolean
}

function SelectTokenFormItem({
  name,
  className,
  form,
  isEdit,
  ...props
}: SelectTokenFormItemProps) {
  const chainId = Form.useWatch('chainId', form)

  const { paymentCurrencies } = useChain({
    chainId: chainId ?? ChainID.A8,
  })

  const tokenItem = useMemo(
    () =>
      paymentCurrencies.map(({ symbol }) => ({
        label: (
          <Space size={16} align="center">
            <div className="select-token-form-item--token-logo">
              <Image
                src={getTokenIcon(symbol)}
                width={26}
                height={26}
                preview={false}
                style={{ borderRadius: '50%' }}
              />
            </div>
            <Typography.Text type="success">
              {symbol.toUpperCase()}
            </Typography.Text>
          </Space>
        ),
        value: symbol,
      })),
    [paymentCurrencies],
  )

  useEffect(() => {
    if (paymentCurrencies.length > 0 && !isEdit) {
      form.setFieldValue(['rewards', ...name], paymentCurrencies[0].symbol)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, paymentCurrencies])

  return (
    <Form.Item
      {...props}
      name={name}
      className={`select-token-form-item ${className}`}
    >
      <Select options={tokenItem} />
    </Form.Item>
  )
}

export default SelectTokenFormItem
