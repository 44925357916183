import ethTokenLogo from 'static/images/token/eth-token.svg'
import usdtTokenLogo from 'static/images/token/usdt-token.svg'
import usdcTokenLogo from 'static/images/token/usdc-token.svg'
import a8TokenLogo from 'static/images/token/a8-token.svg'
import arbTokenLogo from 'static/images/token/arb-token.png'
import bnbTokenLogo from 'static/images/token/bnb-token.svg'
import xtzTokenLogo from 'static/images/token/xtz-token.png'

const tokenIcons: Record<string, string> = {
  eth: ethTokenLogo,
  usdt: usdtTokenLogo,
  usdc: usdcTokenLogo,
  a8: a8TokenLogo,
  arb: arbTokenLogo,
  bnb: bnbTokenLogo,
  xtz: xtzTokenLogo,
}

export const getTokenIcon = (symbol: string = ''): string =>
  tokenIcons[symbol.toLowerCase()] || ethTokenLogo
