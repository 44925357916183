import { useQuery } from 'react-query'

import { ChainID } from 'constant'

import { UserNftService } from 'services/marketplace/evm/userNft'

import { GetNftDetailsDto } from 'types/evm.type'

export const useNftDetails = (
  chainId: ChainID,
  tokenInfo: GetNftDetailsDto,
) => {
  return useQuery({
    queryKey: ['GET_NFT_DETAILS', tokenInfo, chainId],
    queryFn: () => UserNftService.getNftDetail(chainId, tokenInfo),
    enabled: !!tokenInfo.tokenAddress && !isNaN(tokenInfo.tokenId) && !!chainId,
  })
}
