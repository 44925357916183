import { useQuery } from 'react-query'

import { getBlockscoutService } from 'services/blockscout'

import { ChainID } from 'constant'

export const useA8NftBaseInfo = (tokenAddress: string) => {
  return useQuery({
    queryKey: ['get-base-nft-info', tokenAddress],
    queryFn: async () => {
      const blockscoutService = getBlockscoutService(ChainID.A8)
      const { data } = await blockscoutService.getToken(tokenAddress)

      return data
    },
    enabled: !!tokenAddress,
  })
}
