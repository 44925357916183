import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Col, Form, type FormInstance, Image, Row, Tag } from 'antd'
import {
  PlusOutlined as AddIcon,
  DeleteOutlined as RemoveIcon,
} from '@ant-design/icons'

import SelectNft, { type NftSelectPayload } from 'components/systems/select-nft'
import InputLabel from 'components/systems/input/InputLabel'
import WeightFormItem from './shared/WeightFormItem'
import TypeFormItem from './shared/TypeFormItem'
import TierFormItem from './shared/TierFormItem'
import AvailableFormItem from './shared/AvailableFormItem'
import { WrapLoading } from 'components/systems/loading'

import { useNftDetails } from 'hooks/nft/useNftDetail'

import { convertTokenUrl } from 'helper'

import { ChainID } from 'constant'

import { TokenStandard } from 'types/token.type'
import type { RewardItemProps } from '.'

import defaultNftThumbnail from 'static/images/icon/ico-nft.svg'

function NftDetail({
  selectedChain,
  tokenAddress,
  tokenId,
  tokenStandard,
  onRemove = () => {},
}: NftSelectPayload & {
  onRemove?: () => void
}) {
  const { data: nftDetail, isLoading } = useNftDetails(selectedChain, {
    tokenAddress,
    tokenId,
  })

  const image = nftDetail?.token?.icon_url || nftDetail?.image_url || ''

  return (
    <WrapLoading loading={isLoading}>
      <div className="gacha--img-detail" style={{ position: 'relative' }}>
        <Image
          src={convertTokenUrl(image)}
          alt="nft-image"
          fallback={defaultNftThumbnail}
          preview={false}
          style={{ aspectRatio: 1, objectFit: 'cover' }}
          width={100}
          height={100}
        />

        <Tag
          style={{
            position: 'absolute',
            top: 10,
            right: 2,
            borderRadius: 6,
          }}
          color="rgba(0,0,0,0.5)"
        >
          {tokenStandard}
        </Tag>

        <div className="gacha--img-detail--remove">
          <RemoveIcon onClick={onRemove} />
        </div>
      </div>
    </WrapLoading>
  )
}

function RewardNftItem({
  form,
  prefixNamePath,
}: RewardItemProps<{ form: FormInstance }>) {
  const gachaId = useParams()?.gachaId ?? ''
  const isGachaEdit = !!gachaId

  const [open, setOpen] = useState(false)
  const rewardId = Form.useWatch(['rewards', prefixNamePath, '_id'], form)
  const selectedChain = Form.useWatch('chainId', form) ?? ChainID.A8
  const isEdit = !!rewardId

  useEffect(() => {
    if (!isGachaEdit && !!selectedChain) {
      form.setFieldValue(
        ['rewards', prefixNamePath, 'config', 'chainId'],
        selectedChain,
      )

      form.setFieldValue(['rewards', prefixNamePath, 'config'], undefined)
      form.setFieldValue(['rewards', prefixNamePath, 'available'], null)
      form.setFieldValue(['rewards', prefixNamePath, 'total'], null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChain, isGachaEdit])

  return (
    <Fragment>
      <TierFormItem prefixNamePath={prefixNamePath} />

      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues?.rewards?.[prefixNamePath]?.config !==
          curValues?.rewards?.[prefixNamePath]?.config
        }
      >
        {({ getFieldValue, setFieldValue, validateFields }) => {
          const namePath = ['rewards', prefixNamePath, 'config'],
            nftSelected = getFieldValue(namePath),
            uniqueAddress = `${nftSelected?.tokenId}-${nftSelected?.tokenAddress}`,
            isExist =
              !!nftSelected?.tokenAddress &&
              !isNaN(parseInt(nftSelected?.tokenId))

          return (
            <Fragment>
              <Row gutter={[10, 10]}>
                {isExist && (
                  <Col>
                    <NftDetail
                      selectedChain={selectedChain}
                      tokenAddress={nftSelected?.tokenAddress}
                      tokenId={nftSelected?.tokenId}
                      tokenStandard={nftSelected?.tokenStandard}
                      amount={nftSelected?.amount ?? 1}
                      onRemove={() => {
                        setFieldValue(namePath, undefined)
                        setFieldValue(
                          ['rewards', prefixNamePath, 'available'],
                          null,
                        )
                        setFieldValue(
                          ['rewards', prefixNamePath, 'total'],
                          null,
                        )
                      }}
                    />
                  </Col>
                )}
                <Col>
                  <Button className="btn-square" onClick={() => setOpen(true)}>
                    <AddIcon />
                    {isExist ? 'Change NFT' : 'Select NFT'}
                  </Button>
                </Col>
              </Row>

              <Form.Item
                name={[prefixNamePath, 'config']}
                rules={[
                  {
                    required: true,
                    message: 'Please select a NFT',
                  },
                ]}
                noStyle
              />

              <SelectNft
                open={open}
                value={{
                  ...nftSelected,
                  uniqueAddress,
                }}
                onClose={() => setOpen(false)}
                onSelect={({
                  tokenId,
                  tokenAddress,
                  tokenStandard,
                  amount,
                  selectedChain,
                }) => {
                  setFieldValue([...namePath, 'tokenAddress'], tokenAddress)
                  setFieldValue([...namePath, 'tokenId'], tokenId)
                  setFieldValue([...namePath, 'tokenStandard'], tokenStandard)
                  setFieldValue([...namePath, 'chainId'], selectedChain)
                  setFieldValue([...namePath, 'amount'], Number(amount) ?? 1)
                  if (tokenStandard === TokenStandard.ERC721) {
                    setFieldValue(['rewards', prefixNamePath, 'available'], 1)
                    setFieldValue(['rewards', prefixNamePath, 'total'], 1)
                  }

                  if (tokenStandard === TokenStandard.ERC1155) {
                    setFieldValue(
                      ['rewards', prefixNamePath, 'available'],
                      Number(amount) ?? 1,
                    )
                    setFieldValue(
                      ['rewards', prefixNamePath, 'total'],
                      Number(amount) ?? 1,
                    )
                  }
                  validateFields([namePath])
                }}
                form={form}
              />
            </Fragment>
          )
        }}
      </Form.Item>

      {/*
       * Only support for chain A8 now
       * When enabled, pass value on SelectNft
       */}
      <Form.Item name={[prefixNamePath, 'config', 'chainId']} hidden noStyle />

      <Form.Item
        name={[prefixNamePath, 'config', 'tokenStandard']}
        hidden
        noStyle
      />

      {/*
       * NFT always has amount and available = 1 when creating
       */}
      <Form.Item
        name={[prefixNamePath, 'config', 'amount']}
        initialValue={1}
        hidden
        noStyle
      />

      <Form.Item name={[prefixNamePath, 'total']} initialValue={1}>
        <InputLabel label="Total of Rewards" disabled />
      </Form.Item>

      <AvailableFormItem
        prefixNamePath={prefixNamePath}
        initialValue={1}
        onChange={(value) => {
          form.setFieldValue(
            ['rewards', prefixNamePath, 'total'],
            Number(value ?? 1),
          )
          if (isEdit) {
            form.setFieldValue(['rewards', prefixNamePath, 'total'], null)
          }
        }}
      />

      <WeightFormItem prefixNamePath={prefixNamePath} />

      {/* NOTE: for css dom correctly, type must be placed at the very bottom */}
      <TypeFormItem prefixNamePath={prefixNamePath} />
    </Fragment>
  )
}

export default RewardNftItem
