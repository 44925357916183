import { Fragment, useCallback, useEffect, useMemo } from 'react'

import {
  Col,
  Form,
  FormInstance,
  FormListFieldData,
  Select,
  Typography,
} from 'antd'

import { CHAIN_OPTIONS } from 'components/systems/selectChain/selectChain'

import { useChain } from 'hooks/chain/useChain'

import { ChainID } from 'constant'

type Props = {
  prefixPath: FormListFieldData['name']
  form: FormInstance
}

const GachaPriceToken = ({ prefixPath, form }: Props) => {
  const tokenName = Form.useWatch(
    ['variants', prefixPath, 'priceToken', 'tokenName'],
    form,
  )

  const chainId = Form.useWatch('chainId', form)

  const { paymentCurrencies } = useChain({
    chainId: chainId ?? ChainID.A8,
  })

  const symbolOptions = useMemo(
    () =>
      paymentCurrencies?.map((token) => ({
        label: (
          <Typography.Text type="success">
            {token.symbol?.toUpperCase()}
          </Typography.Text>
        ),
        value: token.symbol,
      })) ?? [],
    [paymentCurrencies],
  )

  const currentChainLabel = useMemo(() => {
    const chain = CHAIN_OPTIONS.find((option) => option.value === chainId)
    return chain ? chain.label : 'Unknown Chain'
  }, [chainId])

  const handleSetPriceToken = useCallback(
    (symbol: string) => {
      const tokenAddress =
        paymentCurrencies?.find((token) => token.symbol === symbol)?.address ??
        ''

      form.setFieldValue(
        ['variants', prefixPath, 'priceToken', 'tokenAddress'],
        tokenAddress,
      )
      form.setFieldValue(
        ['variants', prefixPath, 'priceToken', 'tokenName'],
        symbol,
      )

      form.setFieldValue(
        ['variants', prefixPath, 'originalPriceToken', 'tokenAddress'],
        tokenAddress,
      )
      form.setFieldValue(
        ['variants', prefixPath, 'originalPriceToken', 'tokenName'],
        symbol,
      )
    },
    [form, prefixPath, paymentCurrencies],
  )

  useEffect(() => {
    if (tokenName) {
      const firstValue = paymentCurrencies[0].symbol
      const listSymbol = paymentCurrencies.map((item) => item.symbol)

      if (listSymbol.includes(tokenName)) {
        handleSetPriceToken(tokenName)
        return
      }
      form.setFieldValue([prefixPath, 'priceToken', 'tokenName'], firstValue)
      handleSetPriceToken(firstValue)
    }
  }, [form, paymentCurrencies, prefixPath, tokenName, handleSetPriceToken])

  useEffect(() => {
    form.setFieldValue(
      ['variants', prefixPath, 'priceToken', 'chainId'],
      chainId,
    )
    form.setFieldValue(
      ['variants', prefixPath, 'originalPriceToken', 'chainId'],
      chainId,
    )
  }, [form, prefixPath, chainId])

  return (
    <Fragment>
      <Col span={3}>
        <Form.Item name={[prefixPath, 'priceToken', 'chainId']}>
          {currentChainLabel}
        </Form.Item>
      </Col>

      <Col span={3}>
        <Form.Item name={[prefixPath, 'priceToken', 'tokenName']} key={chainId}>
          <Select
            options={symbolOptions}
            onChange={(symbol) => handleSetPriceToken(symbol)}
          />
        </Form.Item>
      </Col>
    </Fragment>
  )
}

export default GachaPriceToken
